import { render } from "./List.vue?vue&type=template&id=426e01b4"
import script from "./List.ts?vue&type=script&lang=ts"
export * from "./List.ts?vue&type=script&lang=ts"

import "./list.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "426e01b4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('426e01b4', script)) {
    api.reload('426e01b4', script)
  }
  
  module.hot.accept("./List.vue?vue&type=template&id=426e01b4", () => {
    api.rerender('426e01b4', render)
  })

}

script.__file = "src/views/Catalog/components/List/List.vue"

export default script